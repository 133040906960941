import React from 'react';
import {PageColor} from '../components/page-color';
import {FaqAccordion} from "../components/faq-accordion";
import {CaseTile} from "../components/case-tile";

export default class Faq extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        var acc = document.getElementsByClassName("faq-accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                /* Toggle between adding and removing the "active" class,
                to highlight the button that controls the panel */
                this.classList.toggle("active");

                /* Toggle between hiding and showing the active panel */
                var panel = this.nextElementSibling;
                if (panel.style.display === "block") {
                    panel.style.display = "none";
                } else {
                    panel.style.display = "block";
                }
            });
        }
    }

    render = () => (
        <main>
            <PageColor color="white" nav="white"/>

            <div className="faq">
                <div className="faq__header">
                    <h1 className="faq__title">
                        <div className="title-poppins">Frequently Asked Questions</div>
                    </h1>
                </div>

                <div className="faq__content">

                    <p className="intro">From catmoms and foodies to travelbirds and carlovers: more than 2000 active influencers are connected to
                        our Native Nation Influencer platform.
                        In just one glance of an eye, we’re able to define your influencer list based on their engagement rate
                        or even authenticity! Brands like Alrpo, Doritos, Alken-Maes, Mars and many more have benefited from our
                        services like this. Contact us to see with your own eyes what the fuss is all about!
                    </p>

                    {this.props.pageContext.website.faqs.map(_faq => <FaqAccordion _faq={_faq}/>)}
                </div>
            </div>
        </main>
    );
}