import React from 'react';
import {Content} from "../content";

export class FaqAccordion extends React.Component {
    constructor () {
        super();
        this.state = {};
    }

    render () {
        return (
            <div>
                <button className="faq-accordion">{this.props._faq.question}</button>
                <div className="faq-accordion-panel">
                    <Content document={this.props._faq.answer} />
                </div>
            </div>
        );
    }
}
