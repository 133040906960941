import React from 'react';
import Link from '../../contentful-engine/link';

export class CaseTile extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  get mainImage () {  
    var file = this.props._case.mainImageLeft.file.url;
    if (file.indexOf('.mp4') > -1) file = this.props._case.socialMediaImage.file.url;
    return file
  }

  render () {
    return (
      <Link className="case-tile" id={ 'case-' + this.props._case.id } to={ this.props._case.slug }>
        <div className="case-tile__content">
          <div className="case-tile__image">
            <img src={ this.mainImage + '?w=620&h=800&fit=fill' } width="310" height="400" />
          </div>
          <div className="case-tile__client title-poppins">{ this.props._case.client }</div>
          <div className="case-tile__title title-ivy">{ this.props._case.name }</div>
          <div className="case-tile__arrow" />
        </div>
      </Link>
    );
  }
}
